import React, { createContext, useContext, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useProfile } from './useProfile';

// Work Schedule Fragment
const workScheduleFragment = gql`
  fragment WorkSchedule on InternalUser {
    id
    workSchedule {
      id
      comment
      returningOn
    }
  }
`;
export const SET_PROVIDER_OUT = gql`
  mutation SetProviderOut($comment: String, $returningOn: Date!) {
    setProviderOut(input: { comment: $comment, returningOn: $returningOn }) {
      success
      internalUser {
        ...WorkSchedule
      }
      mutationErrors {
        messages
      }
    }
  }
  ${workScheduleFragment}
`;

export const SET_PROVIDER_IN = gql`
  mutation SetProviderIn {
    setProviderIn(input: {}) {
      success
      internalUser {
        ...WorkSchedule
      }
      mutationErrors {
        messages
      }
    }
  }
  ${workScheduleFragment}
`;

// Query
const GET_PROVIDER_STATUS = gql`
  query GetProviderSchedule {
    internalUser {
      ...WorkSchedule
    }
  }
  ${workScheduleFragment}
`;

interface WorkSchedule {
  id: string;
  comment: string | null;
  returningOn: string | null;
}
// Provider Status Context
interface ProviderStatusContextValue {
  loading: boolean;
  workSchedule: WorkSchedule | null;
}

const ProviderStatusContext = createContext<ProviderStatusContextValue | undefined>(undefined);

// Provider Status Provider
interface ProviderStatusProviderProps {
  children: React.ReactNode;
}

// eslint-disable-next-line react/function-component-definition
export const ProviderStatusProvider: React.FC<ProviderStatusProviderProps> = ({ children }) => {
  const { hasRole } = useProfile();
  const isProvider = hasRole('PROVIDER');
  const { loading, data } = useQuery(GET_PROVIDER_STATUS, {
    skip: !isProvider,
    fetchPolicy: 'network-only',
  });
  const workSchedule = get(data, 'internalUser.workSchedule', null);
  const providerValue = useMemo(() => ({ loading, workSchedule }), [loading, workSchedule]);

  return (
    <ProviderStatusContext.Provider value={providerValue}>
      {children}
    </ProviderStatusContext.Provider>
  );
};

// Provider Status Hook
export const useProviderStatus = () => {
  const context = useContext(ProviderStatusContext);
  if (!context) {
    throw new Error('useProviderStatus must be used within a ProviderStatusProvider');
  }
  return context;
};
