import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { SET_PROVIDER_IN } from './hooks/useProviderStatus';

function OutOfOfficePopup({
  setCurrentlyOut,
  onClose,
}: {
  setCurrentlyOut: Dispatch<SetStateAction<boolean | null>>;
  onClose: () => void;
}) {
  const [open, setOpen] = useState(true);

  const [setProviderIn] = useMutation(SET_PROVIDER_IN);
  const saveProviderStatus = () => {
    setProviderIn();
    setCurrentlyOut(false);
    setOpen(false);
    onClose();
  };

  const cancelPopup = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog data-cy="out-of-office-popup" open={open} fullWidth onClose={cancelPopup}>
      <DialogTitle sx={{ fontWeight: 'bold', fontSize: '20px' }}>
        {' '}
        Change your status to in office?{' '}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Your status is currently set to out of office, but it looks like you&apos;re back. Want to
          change it to in office?
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" gap={1}>
          <Button data-cy="no-thanks" onClick={cancelPopup}>
            No Thanks
          </Button>
          <Button variant="contained" onClick={saveProviderStatus} data-cy="provider-status-save">
            Yes, Change to in office
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default OutOfOfficePopup;
