import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Radio,
  TextField,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useProviderStatus, SET_PROVIDER_IN, SET_PROVIDER_OUT } from './hooks/useProviderStatus';

interface ProviderStatusPopupProps {
  passProviderStatusVisibility: Dispatch<SetStateAction<boolean>>;
  setOpenToast: Dispatch<SetStateAction<boolean>>;
  setToastMessage: Dispatch<SetStateAction<string>>;
  setCurrentlyOut: Dispatch<SetStateAction<boolean | null>>;
}

function ProviderStatusPopup({
  passProviderStatusVisibility,
  setOpenToast,
  setToastMessage,
  setCurrentlyOut,
}: ProviderStatusPopupProps) {
  const [providerStatus, setProviderStatus] = useState<'in-office' | 'out-of-office' | ''>('');
  const [providerComment, setProviderComment] = useState('');
  const [returnDate, setReturnDate] = useState<Date | null>(new Date());

  const { loading: loadingStatus, workSchedule } = useProviderStatus();

  const [setProviderOut] = useMutation(SET_PROVIDER_OUT);
  const [setProviderIn] = useMutation(SET_PROVIDER_IN);

  useEffect(() => {
    if (!workSchedule) return;

    if (workSchedule.returningOn) {
      setProviderStatus('out-of-office');
      setReturnDate(new Date(workSchedule.returningOn));
      setProviderComment(workSchedule.comment || '');
      setCurrentlyOut(true);
    } else {
      setCurrentlyOut(false);
      setProviderStatus('in-office');
    }
  }, [workSchedule, setCurrentlyOut]);

  const changeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value === 'in-office') setProviderStatus('in-office');
    else setProviderStatus('out-of-office');
  };

  const saveProviderStatus = () => {
    if (providerStatus === 'in-office') {
      setProviderIn();
      setCurrentlyOut(false);
      setToastMessage('Status changed to in-office.');
    } else if (providerStatus === 'out-of-office') {
      if (returnDate! < new Date()) {
        setToastMessage('Error! Must select a future date.');
      } else {
        setProviderOut({
          variables: {
            comment: providerComment,
            returningOn: returnDate,
          },
        });
        setCurrentlyOut(true);
        setToastMessage(
          `Status changed to out-of-office, returning ${Intl.DateTimeFormat('en-us', {
            dateStyle: 'short',
          }).format(returnDate!)}.`,
        );
      }
    }
    setOpenToast(true);
    passProviderStatusVisibility(false);
  };

  const cancelPopup = () => {
    passProviderStatusVisibility(false);
  };

  return (
    <Grid container data-cy="provider-status-popup">
      <Dialog data-cy="provider-status-popup" open fullWidth onClose={cancelPopup}>
        <DialogTitle sx={{ fontWeight: 'bold', fontSize: '20px' }}> Change My Status </DialogTitle>
        {loadingStatus ? (
          <CircularProgress />
        ) : (
          <DialogContent>
            <DialogActions sx={{ p: 1, justifyContent: 'space-between' }}>
              <FormControl>
                <RadioGroup
                  row
                  name="provider-status-buttons-group"
                  onChange={changeStatus}
                  value={providerStatus}
                >
                  <FormControlLabel
                    value="in-office"
                    control={<Radio data-cy="in-office-button" />}
                    label="In Office"
                  />
                  <FormControlLabel
                    value="out-of-office"
                    control={<Radio data-cy="out-of-office-button" />}
                    label="Out Of Office"
                  />
                </RadioGroup>
              </FormControl>
            </DialogActions>
            {providerStatus === 'out-of-office' && (
              <Box>
                <Box sx={{ p: 2 }}>
                  <TextField
                    data-cy="test"
                    value={Intl.DateTimeFormat('en-us', {
                      dateStyle: 'short',
                      timeZone: 'UTC',
                    }).format(returnDate!)}
                    inputProps={{ readOnly: true }}
                    label="Returning on"
                  />
                  <DatePicker
                    data-cy="out-of-office-calendar"
                    inline
                    selected={
                      new Date(
                        Intl.DateTimeFormat('en-us', {
                          dateStyle: 'short',
                          timeZone: 'UTC',
                        }).format(returnDate!),
                      )
                    }
                    onChange={(newDate) => {
                      setReturnDate(newDate);
                    }}
                  />
                </Box>
                <Box sx={{ p: 2 }}>
                  <TextField
                    fullWidth
                    data-cy="out-of-office-comment"
                    label="Comment (optional)"
                    value={providerComment}
                    onChange={(comment) => setProviderComment(comment.target.value)}
                  />
                </Box>
              </Box>
            )}
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={cancelPopup} data-cy="provider-status-cancel">
                Cancel
              </Button>
              <Button onClick={saveProviderStatus} data-cy="provider-status-save">
                Save
              </Button>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </Grid>
  );
}

export default ProviderStatusPopup;
